import { Injectable } from '@angular/core';
import { Vevo } from './vevo.model';
import { RestDataSource } from './rest.datasource';


@Injectable()
export class VevoRepository {
    private vevok: Vevo[] = [];

    constructor() {}

    setVevok(data: Vevo[]) {
        this.vevok = data;
    }

    getVevok(): Vevo[] {
        return this.vevok;
 //           .filter(p => kiado == null || kiado == p.kiado);
    }

    getVevo(azonosito: string): Vevo {
        return this.vevok.find(p => p.azonosito === azonosito);
    }

    getVevoSzam() {
        return this.vevok.length;
    }

}
