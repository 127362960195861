import { Injectable } from '@angular/core';
import { Fogyas } from './fogyas.model';
import { VevoRepository } from '../model/vevo.repository';
import { Vevo } from '../model/vevo.model';

import { RestDataSource } from './rest.datasource';


@Injectable()
export class FogyasRepository {
    public keszleten: Fogyas[] = [];
    public vevok: Vevo[];
    public result: String;
    public fogyasQuantity: number;
    public fogyasAr: number;

    constructor(public datasource: RestDataSource, 
        vevorepository: VevoRepository,) { 

        this.vevok = vevorepository.getVevok();  
        this.datasource.loadFogyas(this.vevok[0].azonosito).subscribe(data => {
            data.forEach(element => {
                element.afa = Number(element.afa);
                element.brutto_ar = Number(element.brutto_ar);
                element.kedvezmeny = Number(element.kedvezmeny);
                element.mennyiseg = Number(element.mennyiseg);
                element.netto_ar = Number(element.netto_ar);
                element.partner_mennyiseg = Number(element.partner_mennyiseg);
            });
            this.keszleten = data;      
        });   
    }

    getFogyasok(): Fogyas[] {
        //console.log(this.keszleten);
        this.fogyasOsszesites();
        return this.keszleten;
    }

    setFogyasok(vevo: string, ean: string, mennyiseg: number, partner_mennyiseg: number) {    
        this.datasource.saveFogyas(vevo, ean, mennyiseg.toString(), partner_mennyiseg.toString()).subscribe(data => {
            let x = data;
        }); 
    }

    fogyasOsszesites() {
        //console.log('lefutott');
        this.fogyasQuantity = 0;
        this.fogyasAr = 0;
        this.keszleten.forEach(l => {
            this.fogyasQuantity += l.partner_mennyiseg;
            this.fogyasAr += (l.partner_mennyiseg * l.brutto_ar);
        });
    }


    

/*
    saveFogyasok(partner: string, partner_mennyiseg: string, termek: string): Observable<string> {
        return this.dataSource.saveFogyas(partner, partner_mennyiseg, termek);
    }
*/   
}