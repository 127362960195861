import { Injectable } from '@angular/core';

@Injectable()
export class BigImage {
    public picture = '';
    public leiras = '';

    addImage(image: string) {
        this.picture = image;
    }

    addSzoveg(szoveg: string) {
        this.leiras = szoveg;
    }
}
