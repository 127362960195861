import { Injectable } from '@angular/core';
import { Product } from './product.model';
import { RestDataSource } from './rest.datasource';
import { Vevo } from '../model/vevo.model';
import { VevoRepository } from '../model/vevo.repository';


@Injectable()
export class ProductRepository {
    private products: Product[] = [];
    private kiadok: string[] = [];
    private vevok: Vevo[];

    public sz1 = ';;;0;0;0;';  //itt lehet beállítani a keresés kezdőértékeit

    public nev_keres = '';
    public szerzo_keres = '';
    public kiado_keres = '';
    public megjelenes = 0;
    public beerkezes = 0;
    public keszlet = 0;
    public kiemeltkiado: string;
    public oldal = 1;
    public elso = true;

    public productsPerPage = 10;

    constructor(private dataSource: RestDataSource, private vevorepository: VevoRepository) {
        this.beerkezes = 0;  // induláskor keresés a 8 hete beérkezett legyen akkor 6
        dataSource.getProducts().subscribe(data => {

        
            this.products = data;

            this.vevok = vevorepository.getVevok();
            this.kiemeltkiado = this.vevok[0].nev;

            this.products.forEach((element) => {
                if (element.kedvezmeny > this.vevok[0].alap_kedvezmeny) {
                    element.kedvezmeny = this.vevok[0].alap_kedvezmeny;
                }
                element.kedv_ar = (element.brutto_ar / 100) * (100 - element.kedvezmeny);
                element.kep = './kepek/' + element.kod + '.png';
            });


        });
    }

    setproductsPerPage(page: number) {
        this.productsPerPage = page;
    }

    setsz1(kereso: string) {
        this.sz1 = kereso;

        const splitted = this.sz1.split(';', 6);   // beérkezés 6 volt  ==> az elmúl 8 hét

        this.nev_keres = splitted[0];
        this.szerzo_keres = splitted[1];
        this.kiado_keres = splitted[2];
        this.megjelenes = +splitted[3];
        this.beerkezes = +splitted[4];
        this.keszlet = +splitted[5];
    }

    getProducts(kiado: string = null): Product[] {
        return this.products
            .filter(p => kiado == null || kiado === p.kiado);
    }

    getProduct(kod: string): Product {
        return this.products.find(p => p.kod === kod);
    }

    setProductQuantity(kod: string, xmennyiseg: number) {
        this.products.forEach((element) => {
            if (element.kod === kod) {
                element.mennyiseg = element.mennyiseg - xmennyiseg;
            }
        });
    }


    getKiadok(): string[] {
        return this.kiadok;
    }

    getWeekNumber(d: Date): number {
        // Copy date so don't modify original
        d = new Date(+d);
        d.setHours(0, 0, 0);
        // Set to nearest Thursday: current date + 4 - current day number
        // Make Sunday's day number 7
        d.setDate(d.getDate() + 4 - (d.getDay() || 7));
        // Get first day of year
        const yearStart = new Date(d.getFullYear(), 0, 1);
        // Calculate full weeks to nearest Thursday
        const weekNo = Math.ceil((((d.valueOf() - yearStart.valueOf()) / 86400000) + 1) / 7);
        // Return array of year and week number
        return weekNo;
    }

    getHetekKulonbsege(d: Date): number {
        const aktualis_het = this.getWeekNumber(new Date());
        const aktualis_ev = new Date().getFullYear();

        if (d.getFullYear() === aktualis_ev) {
            return this.getWeekNumber(d);
        }
        if (d.getFullYear() === aktualis_ev - 1) {
            return (this.getWeekNumber(new Date()) + (53 - this.getWeekNumber(d)));
        }

        return 1000;  // több mit egy év a különbség tehát nem lehet benne az intervallumban
    }


    getFilterProducts(): Product[] {
        const splitted = this.sz1.split(';', 6);
        const het = this.getWeekNumber(new Date());
        let field;
        let direction;
        // Ha beérkezésre van szűrő akkor berérkezés (desc) + megnevezés sorrendbe rendezni ha nem megvezezés (2x csalással)
        if (splitted[4] === '0') {
            field = 'megnevezes';
            direction = 1;
        } else {
            field = 'utolso_bevetel';
            direction = -1;
        }


        return this.products
            .sort((a, b) => {
                if (a[field] === null) {
                    return null
                } else {
                    const compFirst = a[field].localeCompare(b[field]) * direction;
                    const compSec = a['megnevezes'].localeCompare(b['megnevezes']);

                    return compFirst || compSec;
                }    
            }
            )
            .filter(p =>
                ((splitted[0] === '') || (p.kereso_megnevezes.search(splitted[0]) > -1)) &&
                ((splitted[1] === '') || (p.kereso_szerzo.search(splitted[1]) > -1)) &&
                ((splitted[2] === '') || (p.kereso_kiado.search(splitted[2]) > -1)) &&
                ((splitted[3] === '0') || (splitted[3] === '1' && p.ujdonsag === true)) &&

                ((splitted[4] === '0') ||
                    ((splitted[4] === '1') && (het - this.getHetekKulonbsege(new Date(p.utolso_bevetel)) === 0)) ||
                    ((splitted[4] === '2') && (het - this.getHetekKulonbsege(new Date(p.utolso_bevetel)) <= 1) &&
                        (het - this.getHetekKulonbsege(new Date(p.utolso_bevetel)) >= 0)) ||
                    ((splitted[4] === '3') && (het - this.getHetekKulonbsege(new Date(p.utolso_bevetel)) <= 2) &&
                        (het - this.getHetekKulonbsege(new Date(p.utolso_bevetel)) >= 0)) ||
                    ((splitted[4] === '4') && (het - this.getHetekKulonbsege(new Date(p.utolso_bevetel)) <= 3) &&
                        (het - this.getHetekKulonbsege(new Date(p.utolso_bevetel)) >= 0)) ||
                    ((splitted[4] === '5') && (het - this.getHetekKulonbsege(new Date(p.utolso_bevetel)) <= 4) &&
                        (het - this.getHetekKulonbsege(new Date(p.utolso_bevetel)) >= 0)) ||
                    ((splitted[4] === '6') && (het - this.getHetekKulonbsege(new Date(p.utolso_bevetel)) <= 8) &&
                        (het - this.getHetekKulonbsege(new Date(p.utolso_bevetel)) >= 0)))  // &&   //készletre ne szűrjön
                // (splitted[5] === '0') || (splitted[5] === '1' && p.mennyiseg > 0))

            );
    }
}
