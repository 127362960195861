import { Injectable } from '@angular/core';
@Injectable()
export class Vevo {

        public azonosito?: string;
        public szamlazasi_nev?: string;
        public alap_kedvezmeny?: number;
        public rendeles?: string;
        public webnev?: string;
        public letoltesi_mappa?: string;
// Új a gombra szöveg és kereséshez
        public nev?: string;
        public kereso_nev?: string;

}
