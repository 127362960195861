import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Order } from './order.model';
import { RestDataSource } from './rest.datasource';
import { Vevo } from './vevo.model';

@Injectable()
export class OrderRepository {
    private orders: Order[] = [];

    constructor(private dataSource: RestDataSource) {}

    getOrders(): Order[] {
        return this.orders;
    }

    saveOrder(order: string): Observable<string> {
        return this.dataSource.saveOrder(order);
    }

    orderTemp(user: string, cart: string):  Observable<string> {
        this.dataSource.orderTemp(user, cart).subscribe(valasz => {
            //console.log("rendben");
        }, err => {
//hibaüzenet ha nem ment el     
            //console.log("hiba");
        });
        return
        
       // return this.dataSource.authenticate(user, cart);
    }
}
