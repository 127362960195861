import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Product } from './product.model';
import { timeout, catchError } from 'rxjs/operators';
import { Order } from './order.model';
import { Vevo } from './vevo.model';
import { VevoRepository } from './vevo.repository';
import { throwError, concat, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { Fogyas} from './fogyas.model';


const PROTOCOL = 'https';
//const PORT = 443;
//const PORT = 36453;
const PORT = 80;



@Injectable()
export class RestDataSource {
    baseUrl: string;
    auth_token: string;
    header_node = new HttpHeaders();

    //public vevok: Vevo[];
    //public vr: VevoRepository; 



    constructor(private http: HttpClient) {
//        this.baseUrl = `${PROTOCOL}://${location.hostname}:${PORT}/`;
//      this.baseUrl = `${PROTOCOL}://localhost/Model.php`;
 //         this.baseUrl = `${PROTOCOL}://dbvivastest.ddns.net:${PORT}/`;
//   this.baseUrl = `${PROTOCOL}://${'konyv.ddns.net'}:${PORT}/`;
//  this.baseUrl = `${PROTOCOL}://${'sztgyorgy.ddns.net'}:${PORT}/`;
        //         this.baseUrl = `${PROTOCOL}://konyvtest.ddns.net:${PORT}/`;
        

    this.baseUrl = `${PROTOCOL}://${'gptkonyvker.hu/Model.php'}`;

        this.header_node.set('Access-Control-Allow-Origin', '*');
        this.header_node.set('Access-Control-Allow-Methods', 'GET, POST, OPTIONS, PUT, DELETE');
 //this.header_node.set('Access-Control-Allow-Origin', 'http://localhost:3001');
 //       this.header_node.set('content-type', 'application/json');
 //       this.header_node.set('Access-Control-Allow-Headers', 'X-API-KEY, Origin, X-Requested-With, Content-Type, Accept, Access-Control-Request-Method, Authorization');
 //       this.header_node.set('Access-Control-Allow-Methods', 'GET, POST, OPTIONS, PUT, DELETE, PATCH');

    }

    authenticate(user: string, pass: string): Observable<Vevo[]> {
        const xUrl = `${PROTOCOL}://localhost:8080/`;
        const searchParams: HttpParams = new HttpParams().set("crud_req", 'login').set("nev", user).set("jelszo", pass); // angularnal nem kell crud_req paraméter
        //console.log('parameterek:' + searchParams.toString());
        //return this.http.get<Vevo[]>(this.baseUrl + 'login', { params: searchParams });  angularhoz így kell
        return this.http.get<Vevo[]>(this.baseUrl, { params: searchParams });
    }

    loadFogyas(p_vevo: string): Observable<Fogyas[]> {

        const xUrl = `${PROTOCOL}://localhost:8080/`;    
        const searchParams: HttpParams = new HttpParams().set("crud_req", 'loadFogyas').set("vevo", p_vevo);
        //console.log('parameterek:' + searchParams.toString());
        return this.http.get<Fogyas[]>(this.baseUrl, {params: searchParams});

    }

    saveFogyas(p_vevo: string, ean: string, p_mennyiseg: string, p_partner_mennyiseg: string): Observable<string> {
        const bodyP = '';
        const xUrl = `${PROTOCOL}://localhost:8080/`;
        const searchParams: HttpParams = new HttpParams().set("crud_req", 'saveFogyas').set("vevo", p_vevo).set("ean", ean).set("p_mennyiseg",p_partner_mennyiseg).set("mennyiseg", p_mennyiseg);
        //console.log('parameterek:' + searchParams.toString());
        return this.http.put<string>(this.baseUrl, bodyP, { params: searchParams}).pipe(
            timeout(30000),
            map(res => {
                console.log(res);
                console.log(res.toString);
                return res;
            }),
            catchError(err => {
                console.log(err);
                throw throwError(err);
            })
        )   
       

    }

    orderTemp(user: string, cart: string): Observable<string> {
        const bodyP = '';
        const xUrl = `${PROTOCOL}://localhost:8080/`;
        const searchParams: HttpParams = new HttpParams().set("crud_req", 'orderTemp').set("vevo", user).set("rendeles", cart);
        //console.log('parameterek:' + searchParams.toString());
        return this.http.put<string>(this.baseUrl, bodyP, { params: searchParams }).pipe(
            timeout(30000),
            map(res => {
                              //console.log(res.toString);
                return res;
            }),
            catchError(err => {
                          //console.log(err.toString);
                throw throwError(err);
            })
        )
    }


    getProducts(): Observable<Product[]> {
        const xUrl = `${PROTOCOL}://localhost:8080/`;
        const searchParams: HttpParams = new HttpParams().set("crud_req", 'getProduct');
        return this.http.get<Product[]>(this.baseUrl, { params: searchParams });
    }

//    saveOrder(body?: Product | Order, auth: boolean = false): Observable<Order> {
    saveOrder(body: string): Observable<string> {
        const bodyP = '';
        const searchParams: HttpParams = new HttpParams().set("crud_req", 'saveOrder').set("adat", body);
        const xUrl = `${PROTOCOL}://localhost:8080/`; 
        return this.http.put<string>(this.baseUrl, bodyP, {params: searchParams}).pipe(
            timeout(30000),
            map(res => {
                console.log(res);
                return res;
            }),
            catchError(err => {
          console.log(err);
                throw throwError(err);
            })

        );
    }

//    private sendRequest(url: string): Observable<Product[]> {     // Observable<Product | Product[] | Order | Order[]> {

//        return this.http.get<Product[]>(this.baseUrl + url,  {headers: this.header_node});
//    }
}
