import { Component } from '@angular/core';
import { NgForm } from '@angular/forms';
import { OrderRepository } from '../model/order.repository';
import { Order } from '../model/order.model';
import { VevoRepository } from '../model/vevo.repository';
import { Vevo } from '../model/vevo.model';
import { Cart } from '../model/cart.model';
import { ProductRepository } from '../model/product.repository';
import { Router } from '@angular/router';



@Component({
    templateUrl: 'checkout.component.html',
    styleUrls: ['checkout.component.css']
})
export class CheckoutComponent {

    public vevok: Vevo[];

    orderSent = false;
    isDisabled = false;
    private visibleSidebarcheckout: boolean;


    constructor(private router: Router,
                public repository: OrderRepository,
                public order: Order,
                private vevorepository: VevoRepository,
                private cart: Cart,
                private productrepository: ProductRepository) {
                    this.visibleSidebarcheckout= true;
                }


    lezaras_vissza() {
        this.visibleSidebarcheckout = false;
        this.router.navigateByUrl('/cart');
    } 


    submitOrder(form: NgForm) {

        this.isDisabled = true;

        this.vevok = this.vevorepository.getVevok();

        this.vevok.forEach( element => {
            this.order.vevo_nev = element.szamlazasi_nev;
            this.order.vevo_kod = element.azonosito;
        });   
    

// Manuálisan ki kell az ordert pakolni JSON ba mert 2020 óta a beágyazott objektumokat nem tudja
        let orderP = '{"cart":{"lines":[';

        let i = this.cart.lines.length;

        this.cart.lines.forEach( element => {
            this.productrepository.setProductQuantity(element.product.kod, element.quantity);
            orderP += JSON.stringify(element);
            i--;
            if(i > 0)
                orderP += ',';
        });

        orderP += ']},"szoveg":';
        if (this.order.szoveg != null)
            orderP += '"'+this.order.szoveg+'",'
        else        
            orderP += null+',';
        
        orderP += '"vevo_nev":"'+this.order.vevo_nev+'",';    
        orderP += '"vevo_kod": "'+this.order.vevo_kod+'"}';   

        this.repository.saveOrder(orderP).subscribe(valasz => {
            if (valasz.toString() === "true") {
                this.order.clear();
                this.orderSent = true;
                this.isDisabled = false;
                this.visibleSidebarcheckout = false;
                this.router.navigateByUrl('/store');
            }
            else {
                alert("A rendelés elküldése sikertelen, próbálja meg később!");
                this.orderSent = false;
                this.isDisabled = false;   
            }
        }, err => {
//hibaüzenet ha nem ment el     
            alert("A rendelés elküldése sikertelen, próbálja meg később!");
            this.orderSent = false;
            this.isDisabled = false;
        });
    }
}
