import { Injectable } from '@angular/core';
import { Product } from './product.model';
import { OrderRepository } from '../model/order.repository';
import { VevoRepository } from '../model/vevo.repository';
import { Vevo } from '../model/vevo.model';


@Injectable()
export class Cart {
    public lines: CartLine[] = [];
    public line: CartLine;
    public itemCount = 0;
    public cartPrice = 0;
    public cartQuantity = 0;
    public cartNew = true;
    private vevok: Vevo[];

    constructor(private vevorepository: VevoRepository, private orderrepository: OrderRepository) {
        this.vevok = vevorepository.getVevok();
    }


    addLine(product: Product, quantity: number = 1) {
        this.line = this.lines.find(line => line.product.kod === product.kod);
        if (this.line !== undefined) {
            this.line.quantity += quantity;
        } else {
            this.lines.push(new CartLine(product, quantity));
        }

        this.recalculate();
    }

    getProductQuantity(product: Product): number {
        this.line = this.lines.find(line => line.product.kod === product.kod);
        if (this.line !== undefined) {
            return this.line.quantity;
        } else {
            return 0;
        }
    }

    updateQuantity(product: Product, quantity: number) {
        this.line = this.lines.find(line => line.product.kod === product.kod);
        if (this.line !== undefined) {

            if ((this.line.product.mennyiseg >= Number(quantity)) && (Number(quantity) > 0) ) {
                this.line.quantity = Number(quantity);
            } else {
                if (Number(quantity) < 1) {
                    this.line.quantity = 1;
                }
                if (this.line.product.mennyiseg < Number(quantity)) {
                    this.line.quantity = this.line.product.mennyiseg;
                }
            }
        }

        this.recalculate();
    }

    removeLine(kod: string) {
        const index = this.lines.findIndex(line => line.product.kod === kod);
        this.lines.splice(index, 1);
        this.recalculate();

        this.savetempOreder();
    }

    savetempOreder() {
        this.cartNew = false;
        let x = ``;
        let y;
        const z = JSON.stringify(this.lines);
        if (z.length > 10) {
            x = `{"lines":`;
            x += z;
            x += `}`;
        }
        this.orderrepository.orderTemp(this.vevok[0].webnev, x);  //.subscribe(val => y = val);

    }

    clear() {
        this.lines = [];
        this.itemCount = 0;
        this.cartPrice = 0;
        this.cartQuantity = 0;

        this.savetempOreder();
    }

    private recalculate() {
        this.itemCount = 0;
        this.cartPrice = 0;
        this.cartQuantity = 0;
        this.lines.forEach(l => {
            this.itemCount += l.quantity;
            this.cartPrice += (l.quantity * l.product.kedv_ar);
            this.cartQuantity += l.quantity;
        });

        this.savetempOreder();
    }
}

export class CartLine {

    constructor(public product: Product,
        public quantity: number) {}

    get lineTotal() {
        return this.quantity * this.product.kedv_ar;
    }

}
