import { Component } from '@angular/core';
import { Vevo } from '../model/vevo.model';
import { VevoRepository } from '../model/vevo.repository';


@Component({
    selector: 'app-vevo-nev',
    templateUrl: 'vevonev.component.html'
})
export class VevoNevComponent {
    public vevok: Vevo[];

    constructor(public vevorepository: VevoRepository) {
        this.vevok = vevorepository.getVevok();
     }


}