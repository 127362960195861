import { Component } from '@angular/core';
import { Cart } from '../model/cart.model';


@Component({
    selector: 'app-cart-summary',
    templateUrl: 'cartSummary.component.html'
})
export class CartSummaryComponent {
    private visibleSidebar1: boolean;

    constructor(public cart: Cart) { 
        this.visibleSidebar1= true;
    }

    lezaras_stop() {
        this.visibleSidebar1 = false;
    } 
}
