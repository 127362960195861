import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { ModelModule } from '../model/model.module';
import { StoreComponent } from './store.component';
import { CounterDirective } from './counter.directive';
import { CartSummaryComponent } from './cartSummary.component';
import { CartDetailComponent } from './cartDetail.component';
import { CheckoutComponent } from './checkout.component';
import { RouterModule } from '@angular/router';
import { BigImageComponent } from './big-image.component';
import { VevoNevComponent } from './vevonev.component';
import { DialogModule } from 'primeng/dialog';
import { ButtonModule } from 'primeng/button';
import {ToolbarModule} from 'primeng/toolbar';
import {ToastModule} from 'primeng/toast';
import {DataViewModule} from 'primeng/dataview';
import {CheckboxModule} from 'primeng/checkbox';
import {OverlayPanelModule} from 'primeng/overlaypanel';
import {SidebarModule} from 'primeng/sidebar';
import {RadioButtonModule} from 'primeng/radiobutton';
import { DropdownModule } from 'primeng/dropdown';


@NgModule({
    imports: [ModelModule, BrowserModule, FormsModule, RouterModule, DialogModule, ButtonModule, ToolbarModule, DataViewModule, ToastModule, 
        CheckboxModule, RadioButtonModule, SidebarModule, OverlayPanelModule, DropdownModule],
    declarations: [StoreComponent, CounterDirective, CartSummaryComponent, 
    CartDetailComponent, CheckoutComponent, BigImageComponent, VevoNevComponent ],
    exports: [StoreComponent ]
})
export class StoreModule { }
