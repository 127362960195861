import { Component } from '@angular/core';
import { Cart } from '../model/cart.model';
import { Vevo } from '../model/vevo.model';

@Component({
    templateUrl: 'cartDetail.component.html'
})
export class CartDetailComponent {
     public errorMessage = null;
     public vevok: Vevo[];
     private visibleSidebar1: boolean;

    constructor(public cart: Cart) { 
        this.visibleSidebar1= true;
    }

    kosar_stop() {
        this.visibleSidebar1 = false;
    }  
    
    trackCart(index: number, IDcart: Cart) {
        return IDcart.line.product;
    }

}
