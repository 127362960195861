import { NgModule } from '@angular/core';
import { ProductRepository } from './product.repository';
import { VevoRepository } from './vevo.repository';
import { FogyasRepository } from './fogyas.repository';

import { Cart } from './cart.model';
import { Order } from './order.model';
import { OrderRepository } from './order.repository';
import { RestDataSource } from './rest.datasource';
import { BigImage } from './bigImage.model';
import { AuthService } from './auth.service';
import { Vevo } from './vevo.model';


@NgModule({
    imports: [],
    providers: [ProductRepository, Cart, Order, OrderRepository, RestDataSource, AuthService, BigImage, Vevo, VevoRepository, FogyasRepository]

})
export class ModelModule { }
