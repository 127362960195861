import { Component, ViewChild, ElementRef } from '@angular/core';
import { Product } from '../model/product.model';
import { ProductRepository } from '../model/product.repository';
import { Cart } from '../model/cart.model';
import { Router } from '@angular/router';
import { BigImage } from '../model/bigImage.model';
import { VevoRepository } from '../model/vevo.repository';
import { Vevo } from '../model/vevo.model';
import { saveAs } from 'file-saver';
//import { MessageService } from 'primeng/api';
//import { PrimeNGConfig } from 'primeng/api';
import { HostListener } from '@angular/core';
import { Fogyas } from '../model/fogyas.model';
import { FogyasRepository } from '../model/fogyas.repository';
import { RestDataSource } from 'app/model/rest.datasource';
import { Sidebar } from 'primeng/sidebar';
import { CheckboxModule } from 'primeng/checkbox';
import jsPDF from 'jspdf';
import { MatPaginatorModule } from '@angular/material/paginator';
import 'assets/PTC55F-normal.js';


type lineHeightFunction = (fontSize: number) => number;

interface IDocumentConfig {
    pageHeight: number;
    pageWidth: number;
    getLineHeight: lineHeightFunction;
    margin: number;
    normalFontSize: number;
    titleFontSize: number;
    numberOfPages: number;
}

@Component({
    selector: 'app-store',
    templateUrl: 'store.component.html' //,
    //providers: [MessageService],
})

export class StoreComponent {
    public selectedKiado = null;
    public selectedPage = 1;
    public kepforras: string;
    public vevok: Vevo[];
    public adatok: boolean;
    public sortType: string;
    public display: boolean;
    private visibleSidebarfogyas: boolean;
    public fogyas_tetel: Fogyas;
    public eredeti_fogyas_tetel: number;
    public azon: string;
    public tempString: string = '';
    public idx: number;
    public kamuTxt: string;
    public kereostr: string;
    public siker: string;
    public uzenet_fej: string;
    public uzenet_szoveg: string;
    public sidebarVisible: boolean;
    public checked: boolean = false;


    constructor(public repository: ProductRepository,
        private cart: Cart,
        private bigimage: BigImage,
        private router: Router,
        //private messageService: MessageService,
        //private primengConfig: PrimeNGConfig,
        private vevorepository: VevoRepository,
        public datasource: RestDataSource,
        public fogyasreposity: FogyasRepository) {

        let i = 0;
        if ((this.cart.lines != null)) {
            this.cart.lines.forEach(l => i++);
        }
        if ((i === 0) && (this.cart.cartNew)) {
            let tempCart: Cart;
            let tempProduct: Product;
            let tempMenny = 0;

            this.vevok = vevorepository.getVevok();
            this.display = false;
            if (this.vevok[0].letoltesi_mappa) {
                this.adatok = true;
            } else {
                this.adatok = false;
            }

            if ((this.vevok[0].rendeles != null) && (this.vevok[0].rendeles.length > 0)) {
                tempCart = JSON.parse(this.vevok[0].rendeles);
                tempCart.lines.forEach(l => {
                    tempProduct = l.product;
                    tempMenny = l.quantity;
                    this.cart.addLine(tempProduct, tempMenny);
                });
            }
        }
        this.changePage(repository.oldal);
    }

    //ngOnInit() {
    //this.primengConfig.ripple = true;
    //    this.visibleSidebarfogyas = true;
    //  }

    // betöltés után a fogyásra menjen
    ngAfterViewInit() {
        if (this.repository.elso) {
            this.fogyas_start();
            this.repository.elso = false;
        }
    }

    @HostListener('window:keypress', ['$event'])
    keyEvent(event: KeyboardEvent): void {
        let vonalkod: string;
        if ((document.activeElement === document.getElementById("scan")) && (this.visibleSidebarfogyas)) {
            if (event.key === 'Enter') {
                // The QR/Bar code is ready here
                // Do something here with the scanned code  
                if (this.tempString === '' || this.kamuTxt != '') {
                    vonalkod = this.kamuTxt;
                } else {
                    vonalkod = this.tempString;
                }
                //                console.log(vonalkod);
                this.idx = this.fogyasreposity.keszleten.findIndex(x => x.ean === vonalkod);
                //                console.log(this.idx);
                if (this.idx === -1) {
                    this.showError('Hiba', 'Ez a termék nincs készleten');
                } else {
                    this.fogyas_tetel = this.fogyasreposity.keszleten[this.idx];
                    this.fogyas_valtozas(this.fogyas_tetel, 1);
                }
                this.tempString = '';
                this.kamuTxt = '';
            } else {
                if (event.key === 'ö') {
                    this.tempString += '0';
                } else {
                    this.tempString += event.key;
                }
            }
        }
    }
    @HostListener('window:keyup', ['$event'])
    keyEvent2(event: KeyboardEvent): void {
        if ((document.activeElement === document.getElementById("kereses_input")) && (this.visibleSidebarfogyas)) {
            this.fogyas_kereses()
        }

    }

    createPdfHeader(
        doc: jsPDF,
        {
            pageWidth,
            getLineHeight,
            margin,
            titleFontSize,
            normalFontSize,
        }: IDocumentConfig
    ): number {
        let cim = 'Fogyás leadás  ' + new Date().toLocaleString();
        let currentHeight = margin;
        let sorban: number = margin;
        doc.setFontSize(titleFontSize);
        doc.text(cim, margin, 10);
        currentHeight += getLineHeight(titleFontSize);
        doc.setFontSize(normalFontSize);

        doc.setFillColor(102,178,255)
        doc.rect(margin,currentHeight - (normalFontSize / 2)-1, pageWidth - (margin * 2), normalFontSize,'F')
        sorban += 22;
        doc.text('Termék', sorban, currentHeight);
        sorban += 57;
        doc.text('Kiadó', sorban, currentHeight);
        sorban += 33;
        doc.text('Vonalkód', sorban, currentHeight);
        sorban += 25;
        doc.text('Fogyár', sorban, currentHeight);
        sorban += 17;
        doc.text('Készlet', sorban, currentHeight);
        sorban += 14;
        doc.text('Fogyás', sorban, currentHeight);
        currentHeight += getLineHeight(normalFontSize);
        doc.line(margin, currentHeight, pageWidth - margin, currentHeight);
        return currentHeight + getLineHeight(normalFontSize);
    }

    createPdfFooter(
        doc: jsPDF,
        {
            pageWidth,
            pageHeight,
            getLineHeight,
            margin,
            normalFontSize,
            numberOfPages,
        }: IDocumentConfig
    ): void {
        for (let i = 1; i <= numberOfPages; i++) {
            doc.setPage(i);
            doc.line(
                margin,
                pageHeight - margin - getLineHeight(normalFontSize) + 4,
                pageWidth - margin,
                pageHeight - margin - getLineHeight(normalFontSize) + 4
            );
            doc.text(i + '/' + numberOfPages, pageWidth / 2, pageHeight - margin - getLineHeight(normalFontSize) + 10, { align: 'center' });
        }
    }


    SavePDF() {
        const doc = new jsPDF("p", "mm", "a4");
        doc.setFont('PTC55F', 'normal');
        const pageHeight = doc.internal.pageSize.height || doc.internal.pageSize.getHeight();
        const pageWidth = doc.internal.pageSize.width || doc.internal.pageSize.getWidth();
        const getLineHeight = (fontSize: number): number => fontSize / 2;
        const margin = 15;
        const normalFontSize = 10;
        const titleFontSize = 14;
        let currentPage = 1;
        let fieldS: string[];
        let magas: number;
        let sorban: number;
        let j: number;
        let osszar: number = 0;
        let osszmenny: number = 0;

        let config: IDocumentConfig = {
            pageHeight: pageHeight,
            pageWidth: pageWidth,
            getLineHeight: getLineHeight,
            margin: margin,
            normalFontSize: normalFontSize,
            titleFontSize: titleFontSize,
            numberOfPages: currentPage,
        };

        let currentHeight = this.createPdfHeader(doc, config);

        const table = document.getElementById("fogyas_table");
        const tr = table.getElementsByTagName("tr");
        for (let i = 0; i < tr.length; i++) {
            if (tr[i].style.display != "none") {
                const td3 = tr[i].getElementsByTagName("td")[2];
                if (td3) {
                    const ean = (td3.textContent || td3.innerText).trim();
                    this.idx = this.fogyasreposity.keszleten.findIndex(x => x.ean === ean);
                    if (this.idx >= 0) {
                        if (currentHeight > pageHeight - (margin + getLineHeight(normalFontSize))) {
                            doc.addPage();
                            currentPage++;
                            config.numberOfPages = currentPage;
                            currentHeight = this.createPdfHeader(doc, config);
                        }
                        sorban = margin;
                        fieldS = doc.splitTextToSize(this.fogyasreposity.keszleten[this.idx].megnevezes, 64);
                        doc.text(fieldS, sorban, currentHeight);
                        sorban += 66;
                        j = 0;
                        fieldS.forEach(lineText => { j++ });
                        magas = getLineHeight(normalFontSize) * j;
                        fieldS = doc.splitTextToSize(this.fogyasreposity.keszleten[this.idx].kiado, 45);
                        doc.text(fieldS, sorban, currentHeight);
                        sorban += 42;
                        j = 0;
                        fieldS.forEach(lineText => { j++ });
                        if (getLineHeight(normalFontSize) * j > magas) {
                            magas = getLineHeight(normalFontSize) * j;
                        }
                        fieldS = doc.splitTextToSize(this.fogyasreposity.keszleten[this.idx].ean, 30);
                        doc.text(fieldS, sorban, currentHeight);
                        sorban += 45;
                        fieldS = doc.splitTextToSize(this.fogyasreposity.keszleten[this.idx].brutto_ar.toString(), 10);
                        doc.text(fieldS + ' Ft', sorban, currentHeight, { align: 'right' });
                        sorban += 15;
                        fieldS = doc.splitTextToSize(this.fogyasreposity.keszleten[this.idx].mennyiseg.toString(), 5);
                        doc.text(fieldS, sorban, currentHeight, { align: 'right' });
                        sorban += 12;
                        fieldS = doc.splitTextToSize(this.fogyasreposity.keszleten[this.idx].partner_mennyiseg.toString(), 5);
                        doc.text(fieldS, sorban, currentHeight, { align: 'right' });
                        currentHeight += magas;
                        if (this.fogyasreposity.keszleten[this.idx].partner_mennyiseg > 0) {
                            osszmenny += this.fogyasreposity.keszleten[this.idx].partner_mennyiseg;
                            osszar += (this.fogyasreposity.keszleten[this.idx].partner_mennyiseg *
                                this.fogyasreposity.keszleten[this.idx].brutto_ar);
                        }
                    }
                }
            }
        }
        if (currentHeight > pageHeight - (margin + getLineHeight(normalFontSize + getLineHeight(titleFontSize)))) {
            doc.addPage();
            currentPage++;
            config.numberOfPages = currentPage;
            currentHeight = this.createPdfHeader(doc, config);
        }
        doc.setFontSize(titleFontSize);
        doc.text('Összesen: ' + osszmenny.toString() + ' darab  ' + osszar.toString() + ' Ft', 70, currentHeight);
        doc.setFontSize(normalFontSize);

        this.createPdfFooter(doc, config);

        window.open(doc.output('bloburl'));
        //doc.save("fogyas.pdf");
    }


    trackProduct(index: number, IDproduct: Product) {
        return IDproduct.ean;
    }

    csakKeszlet() {
        if (this.checked) {
            let inp: any = document.getElementById("kereses_input") as HTMLInputElement | null;
            inp.value = '';
        }
        this.fogyas_kereses();
    }

    showError(sum: string, det: string) {
        //this.messageService.add({ severity: 'error', summary: sum, detail: det});
        this.uzenet_fej = sum;
        this.uzenet_szoveg = det;
        this.sidebarVisible = true;
    }
    showSuccess(sum: string, det: string) {
        this.uzenet_fej = sum;
        this.uzenet_szoveg = det;
        this.sidebarVisible = true;
    }

    fogyas_valtozas(elem: Fogyas, menny: number) {
        if (elem.partner_mennyiseg + menny < 0) {
            this.showError('Hiba', 'A fogyás nem lehet negatív!');
        } else {
            if (menny > elem.mennyiseg) {
                this.showError('Hiba', 'A fogyás nem lehet nagyobb mint a készlet!');
            } else {
                elem.partner_mennyiseg += menny;
                elem.mennyiseg -= menny;
                this.fogyasreposity.setFogyasok(elem.partner, elem.ean, elem.mennyiseg, elem.partner_mennyiseg);
                this.fogyasreposity.fogyasOsszesites();
                this.fogyas_kereses();
                //if (menny > 0) {
                //    this.showSuccess('Sikeres művelet', 'Hozzáadva a fogyáshoz: ' + elem.megnevezes);
                //} 
            }
        }
    }

    fogyas_kereses() {
        const accents = 'áéíóöőúüű';
        const replace = 'aeiooouuu';
        let txtValue: string;
        let pmennyiseg: string;
        let ean: string;
        let input, filter, table, tr, td, td2, i, td3;
        input = document.getElementById("kereses_input");
        filter = input.value.toLowerCase();
        table = document.getElementById("fogyas_table");
        tr = table.getElementsByTagName("tr");
        for (i = 0; i < tr.length; i++) {
            td = tr[i].getElementsByTagName("td")[9];
            td2 = tr[i].getElementsByTagName("td")[6];
            td3 = tr[i].getElementsByTagName("td")[2];
            if (td) {
                pmennyiseg = td2.textContent || td2.innerText;
                pmennyiseg = pmennyiseg.trim();
                txtValue = td.textContent || td.innerText;
                txtValue = txtValue.toLowerCase();
                ean = td3.textContent || td3.innerText;
                ean = ean.trim();
                let temp = '';
                for (const char of filter) {
                    const pos = accents.search(char);
                    if (pos > -1) {
                        temp += replace[pos];
                    } else {
                        temp += char;
                    }
                }
                if (txtValue.indexOf(temp) > -1) {
                    tr[i].style.display = "";
                } else {
                    tr[i].style.display = "none";
                }
                //console.log(ean);
                this.idx = this.fogyasreposity.keszleten.findIndex(x => x.ean === ean);
                if ((this.checked) && (this.fogyasreposity.keszleten[this.idx].partner_mennyiseg === 0)) {              //(pmennyiseg === '0')) {
                    tr[i].style.display = "none";
                }
            }
        }
    }

    fogyas_start() {
        this.visibleSidebarfogyas = true;
    }

    get products(): Product[] {
        const pageIndex = (this.selectedPage - 1) * this.repository.productsPerPage;
        return this.repository.getFilterProducts()
            .slice(pageIndex, pageIndex + this.repository.productsPerPage);
    };

    get fogyasok(): Fogyas[] {
        return this.fogyasreposity.getFogyasok();
    }


    fogyas_stop() {
        this.visibleSidebarfogyas = false;
    }



    startDownload() {
        saveAs(this.vevok[0].letoltesi_mappa + 'GPTADAT.ARJ', 'GPTADAT.ARJ');
        this.display = false;
    };

    showDialog() {
        this.display = true;
        //  this.download();
    };

    kiemelt() {

        this.repository.nev_keres = '';
        this.repository.kiado_keres = this.repository.kiemeltkiado;
        this.repository.szerzo_keres = '';
        this.repository.megjelenes = 0;
        this.repository.beerkezes = 0;

        this.kereses();

    }



    kereses() {
        const accents = 'áéíóöőúüű';
        const replace = 'aeiooouuu';

        this.repository.nev_keres = this.repository.nev_keres.toLowerCase();
        this.repository.kiado_keres = this.repository.kiado_keres.toLowerCase();
        this.repository.szerzo_keres = this.repository.szerzo_keres.toLowerCase();

        let temp = '';
        for (const char of this.repository.nev_keres) {
            const pos = accents.search(char);
            if (pos > -1) {
                temp += replace[pos];
            } else {
                temp += char;
            }
        }

        temp += ';';
        for (const char of this.repository.szerzo_keres) {
            const pos = accents.search(char);
            if (pos > -1) {
                temp += replace[pos];
            } else {
                temp += char;
            }
        }

        temp += ';';
        for (const char of this.repository.kiado_keres) {
            const pos = accents.search(char);
            if (pos > -1) {
                temp += replace[pos];
            } else {
                temp += char;
            }
        }

        temp += ';';

        temp += this.repository.megjelenes;
        temp += ';';

        if (this.repository.elso) {
            // if (this.repository.beerkezes === 6) {  // Ha változtatott s beérkezésen akkor semmi, de ha nem  akkor
            //     this.repository.beerkezes = 0;     // Az első Keresés után álljon vissza a bérkrzés az "Összesre"
            // }
            this.repository.elso = false;
        }

        temp += this.repository.beerkezes;
        temp += ';';

        temp += this.repository.keszlet;
        temp += ';';

        this.repository.setsz1(temp);

        this.changePage(1);

    }


    changeKiado(newKiado?: string) {
        this.selectedKiado = newKiado;
    }

    changePage(newPage: number) {
        this.selectedPage = newPage;
        this.repository.oldal = newPage;
    }

    changePageSize(newSize: number) {
        this.repository.setproductsPerPage(Number(newSize));
        this.changePage(1);
    }

    get pageCount(): number {
        return Math.ceil(this.repository
            .getFilterProducts().length / this.repository.productsPerPage);
    }

    addProductToCart(product: Product) {
        if (product.mennyiseg > this.cart.getProductQuantity(product)) {
            this.cart.addLine(product);
        } else {
            this.showError('Hiba', 'A mennyiség nagyobb mint a rendelhető!');
        }

    }

    openNewWindow(kep: string, leiras: string) {
        this.bigimage.addImage(kep);
        this.bigimage.addSzoveg(leiras);
        this.router.navigateByUrl('/bigpic');
    }

    getVevo(): string {
        let nev: string;
        this.vevok = this.vevorepository.getVevok();


        this.vevok.forEach(element => {
            nev = element.szamlazasi_nev;
        });

        return nev;
    }

    gotoCart() {
        this.router.navigateByUrl('/cart');

    }

}
