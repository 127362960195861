import { Component} from '@angular/core';
import { BigImage } from '../model/bigImage.model';


@Component({
  selector: 'app-big-image',
  templateUrl: './big-image.component.html',
})
export class BigImageComponent {

  constructor(public nagykep: BigImage) { }

}
