import { Injectable } from '@angular/core';
import { Cart } from './cart.model';

@Injectable()
export class Order {
    public id: number;
    public szoveg: Text = null;
    public vevo_kod: string;
    public vevo_nev: string;

    constructor(public cart: Cart) { }

    clear() {
        this.id = null;
        this.szoveg = this.vevo_kod = this.vevo_nev = null;
        this.cart.clear();
    }
}
