import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { RestDataSource } from './rest.datasource';
import { Vevo } from './vevo.model';


@Injectable()
export class AuthService {
    constructor(private datasource: RestDataSource) {}

    authenticate(username: string, password: string): Observable<Vevo[]> {  
        return this.datasource.authenticate(username, password);
    }

    clear() {
        this.datasource.auth_token = null;
    }
}
