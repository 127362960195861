import { Component } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from '../model/auth.service';
import { Vevo } from '../model/vevo.model';
import { VevoRepository } from '../model/vevo.repository';


@Component({
    selector: 'app-auth',
    templateUrl: 'auth.component.html'
})

export class AuthComponent {
    public username: string;
    public password: string;
    public errorMessage: string;
    vevo: Vevo;


    constructor(private router: Router,
                private auth: AuthService,
                private vevorepository: VevoRepository) {}

    authenticate(form: NgForm) {
        if (form.valid) {
// perform authentication
            this.auth.authenticate(this.username, this.password).
                subscribe(data => {  

                this.vevorepository.setVevok(data);
                    if (this.vevorepository.getVevoSzam() > 0) {
                        this.router.navigateByUrl('/store');
                       }
                this.errorMessage = 'Hibás felhasználói név vagy jelszó';
                }
            );
        } else {
            this.errorMessage = 'Az adatok kitöltése kötelező';
    }
    }
}
