import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';


import { AppComponent } from './app.component';
import { StoreModule } from './store/store.module';
import { StoreComponent } from './store/store.component';
import { AuthModule } from './auth/auth.module';
import { AuthComponent } from './auth/auth.component';
import { CheckoutComponent } from './store/checkout.component';
import { CartDetailComponent } from './store/cartDetail.component';
import { RouterModule } from '@angular/router';
import { StoreFirstGuard } from './storeFirst.guard';
import { BigImageComponent } from './store/big-image.component';
import { LOCALE_ID } from '@angular/core';

import { registerLocaleData } from '@angular/common';
import localeHu from '@angular/common/locales/hu';
import localeHuExtra from '@angular/common/locales/extra/hu';
import { HttpClientModule } from "@angular/common/http";

registerLocaleData(localeHu, 'hu-Hu', localeHuExtra);



@NgModule({
   imports: [BrowserModule, HttpClientModule, StoreModule, AuthModule, BrowserAnimationsModule,
        RouterModule.forRoot([
    { path: 'auth', component: AuthComponent,
        canActivate: [StoreFirstGuard]
    },
    { path: 'store', component: StoreComponent,
        canActivate: [StoreFirstGuard] },
    { path: 'bigpic', component: BigImageComponent,
        canActivate: [StoreFirstGuard] },
    { path: 'cart', component: CartDetailComponent,
        canActivate: [StoreFirstGuard] },
    { path: 'checkout', component: CheckoutComponent,
        canActivate: [StoreFirstGuard] },
    { path: '**', redirectTo: '/auth' }
], {})],
    // providers: [StoreFirstGuard],
    providers: [StoreFirstGuard, { provide: LOCALE_ID, useValue: 'hu-HU' }],
    declarations: [AppComponent],
    bootstrap: [AppComponent]
})
export class AppModule { }
